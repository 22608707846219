import React from 'react';

const InputWithLabel = ({ label, inputType = 'text', placeholder = '', pattern = null, value = '', onChange, isRequired = false }) => {
    return (
        <div className='mb-3'>
            <label className='mb-1'>
                <strong>{label}</strong>{isRequired && <span className='text-warning'> *</span>}
            </label>
            <input
                type={inputType}
                className='form-control'
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                required={isRequired}
                pattern={pattern ? pattern : undefined}
            />
        </div>
    )
}

export default InputWithLabel;
