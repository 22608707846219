import axios from "axios";

export const API = axios;

export const SessionStorage = sessionStorage;

export const LocalStorage = localStorage;

export const API_URL = process.env.REACT_APP_GIRVI_API_URL;

export const Authtoken = '1234';
