import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { API, API_URL, Authtoken, LocalStorage } from '../../constant';
import { toast } from 'react-toastify';
import loginImageLogo from '../../images/tcul-images/login-logo.png';
import staticProfileImage from '../../images/tcul-images/user-img.png';
import InputWithLabel from '../../components/InputWithLabel';
import SelectWithLabel from '../../components/SelectWithLabel';

const RegisterForm = () => {
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState({});
  const [profileImage, setProfileImage] = useState(null);
  const [stateOptions, setStateOptions] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);
  const [isTermsChecked, setIsTermsChecked] = useState(false);

  useEffect(() => {
    const isUserLoggedIn = LocalStorage.getItem('authToken');
    if (isUserLoggedIn) {
      navigate('/');
    }
  }, [])

  const fetchAllStates = useCallback(async () => {
    try {
      const response = await API.post(`${API_URL}/states`,
        {
          authtoken:1234
        }
      );
      setStateOptions(response?.data?.states ?? []);
    } catch (error) {
      console.error('Error fetching states:', error);
    }
  }, []);

  useEffect(() => {
    fetchAllStates();
  }, [fetchAllStates]);

  const fetchAllCities = useCallback(async () => {
    if (!userInfo?.state) return;

    try {
      const response = await API.post(`${API_URL}/cities`, {
        state: userInfo.state,
      });
      setCityOptions(response ?? []);
    } catch (error) {
      console.error('Error fetching cities:', error);
    }
  }, [userInfo?.state]);

  useEffect(() => {
    fetchAllCities();
  }, [fetchAllCities]);

  const handleImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      reader.onload = (e) => setProfileImage(e.target.result);
      reader.readAsDataURL(event.target.files[0]);
    }
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    if (!isTermsChecked) {
      alert('Please agree to the Terms and Conditions');
      return;
    } else {
      if (userInfo && userInfo.business_name && userInfo.first_name && userInfo.last_name && userInfo.mobile_number && userInfo.state && userInfo.city) {
        try {
          const response = await API.post(`${API_URL}/register`, {
            photo: profileImage,
            business_name: userInfo.business_name,
            first_name: userInfo.first_name,
            last_name: userInfo.last_name,
            mobile: userInfo.mobile_number,
            state: userInfo.state,
            city: userInfo.city,
            authtoken: Authtoken,
            device_id: '1234',
          });
          console.log('register user api call responce', response.data);
          toast.success('Registration successful!');
          setUserInfo(null);
          navigate('/user-login');
        } catch (error) {
          console.log('register user api call error', error);
        }
      }
    }
  };

  return (
    <div className='authincation' style={{ height: '100vh', display: 'flex', alignItems: 'center' }}>
      <div className='container h-100'>
        <div className='row justify-content-center h-100 align-items-center'>
          <div className='col-lg-8 col-md-10 col-sm-12'>
            <div className='authincation-content'>
              <div className='row no-gutters'>
                <div className='col-12'>
                  <div className='auth-form'>
                    <div className='text-center mb-3'><img src={loginImageLogo} alt='' /></div>
                    <h1 className='text-center fw-bold mb-0'>Registration</h1>
                    <p className='text-center mb-4'>Create your account and start managing</p>

                    <form onSubmit={handleFormSubmit}>
                      <div className='d-flex justify-content-center'>
                        <div className='profile-container' style={{ position: 'relative', textAlign: 'center', marginBottom: '20px' }}>
                          <label htmlFor='file-upload' style={{ cursor: 'pointer' }}>
                            <img
                              src={profileImage || staticProfileImage}
                              alt=''
                              style={{
                                width: '120px',
                                height: '120px',
                                borderRadius: '60%',
                                objectFit: 'cover',
                                display: 'block',
                                margin: '0 auto',
                              }}
                            />
                            <div className='upload-icon' style={{ position: 'absolute', bottom: '0', right: '0' }}>
                              <i className='fas fa-camera'></i>
                            </div>
                          </label>
                          <input
                            id='file-upload'
                            type='file'
                            accept='image/*'
                            onChange={handleImageChange}
                            style={{ display: 'none' }}
                          />
                        </div>
                      </div>

                      <div className='row'>
                        <div className='mb-3 col-md-6'>
                          <InputWithLabel
                            label='Business name'
                            inputType='text'
                            placeholder='Enter business name'
                            isRequired={true}
                            value={userInfo.business_name || ''}
                            onChange={(e) => setUserInfo({ ...userInfo, business_name: e.target.value })}
                          />
                        </div>

                        <div className='mb-3 col-md-6'>
                          <InputWithLabel
                            label='First name'
                            inputType='text'
                            placeholder='Enter first name'
                            isRequired={true}
                            value={userInfo.first_name || ''}
                            onChange={(e) => setUserInfo({ ...userInfo, first_name: e.target.value })}
                          />
                        </div>

                        <div className='mb-3 col-md-6'>
                          <InputWithLabel
                            label='Last name'
                            inputType='text'
                            placeholder='Enter last name'
                            isRequired={true}
                            value={userInfo.last_name || ''}
                            onChange={(e) => setUserInfo({ ...userInfo, last_name: e.target.value })}
                          />
                        </div>

                        <div className='mb-3 col-md-6'>
                          <InputWithLabel
                            label='Mobile number'
                            inputType='tel'
                            placeholder='Enter mobile number'
                            isRequired={true}
                            pattern='[0-9]+'
                            value={userInfo.mobile_number || ''}
                            onChange={(e) => setUserInfo({ ...userInfo, mobile_number: e.target.value })}
                          />
                        </div>

                        <div className='mb-3 col-md-6'>
                          <SelectWithLabel
                            label='State'
                            placeholder='Select state'
                            isRequired={true}
                            options={stateOptions}
                            value={userInfo.state || ''}
                            onChange={(e) => setUserInfo({ ...userInfo, state: e.target.value })}
                          />
                        </div>

                        <div className='mb-3 col-md-6'>
                          <SelectWithLabel
                            label='City'
                            placeholder='Select city'
                            isRequired={true}
                            options={cityOptions}
                            value={userInfo.city || ''}
                            onChange={(e) => setUserInfo({ ...userInfo, city: e.target.value })}
                          />
                        </div>

                        <div className='form-group mt-3 mb-0'>
                          <div className='form-check custom-checkbox ms-1' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <input
                              type='checkbox'
                              className='form-check-input'
                              id='basic_checkbox_1'
                              checked={isTermsChecked}
                              onChange={(e) => setIsTermsChecked(e.target.checked)}
                            />
                            <label className='form-check-label' htmlFor='basic_checkbox_1' style={{ marginLeft: '8px' }}>
                              I have read the <a href='https://girviapp.com/terms-and-conditions' className='theme-color'>Terms and Conditions</a> & <a href='https://girviapp.com/privacy-policy' className='theme-color'>Privacy Policy</a>
                            </label>
                          </div>
                        </div>
                      </div>

                      <div className='text-center mt-4'>
                        <button type='submit' className='btn btn-primary btn-block'>Register</button>
                      </div>
                    </form>

                    <div className='new-account text-center mt-3'>
                      <p>Already have an account? <a className='text-primary' href='/user-login'>Login</a></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisterForm;
