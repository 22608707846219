import React from 'react';
import Header from './components/Header';
import Footer from './components/Footer';
import Sidebar from './components/Sidebar';
import useDeviceType from './utils/useDeviceType';

const Layout = ({ children }) => {
  const deviceType = useDeviceType(); 

  return (
    <div style={{ display: 'flex', minHeight: '100vh' }}>
      <div style={{ position: 'fixed', top: 0, left: 0, width: '20%', zIndex: 2, display: deviceType === 'mobile' ? 'none' : 'block' }}>
        <Sidebar />
      </div>
      <div style={{ flexGrow: 1, zIndex: 1 }}>
        <div style={{ position: 'absolute', top: 0, right: 0, width: deviceType === 'mobile' ? '100%' : '82%' }}>
          <Header />
        </div>
        <div style={{ marginTop: '8vh', marginBottom: '8vh' }}>
          {children}
        </div>
        <div style={{ position: 'absolute', bottom: 0, right: 0, width: deviceType === 'mobile' ? '100%' : '82%' }}>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default Layout;
