import React from 'react';
import PropTypes from 'prop-types';

const LoanCard = ({ rank, date, amount, percentage, tenure, principalPaid, interestPaid, principalPending, interestPending, interestPerMonth }) => (
  <div className='col-lg-6 col-sm-12 mb-3'>
    <div className='table-responsive table-hover fs-14' style={{ backgroundColor: '#ffffff', borderRadius: '18px' }}>
      <table className='table display mb-0 dataTablesCard font-w600 short-one border-no card-table text-black'>
        <thead>
          <tr>
            <th className='px-2'>
              <span className='bg-success text-white rank-ic fs_14' style={{ width: '43px', height: '43px', verticalAlign: 'middle', lineHeight: '43px' }}>#{rank}</span>
            </th>
            <th className='fs_14'>{date}</th>
            <th className='fs_14' style={{display: 'flex', flexDirection: 'column'}}>
              <span>{amount}</span>
              <span>{percentage}</span>
            </th>
            <th className='fs_14 pe-0'>{tenure}</th>
          </tr>
        </thead>
      </table>

      <div className='card-body py-2 px-3'>
        <div className='px-0'>
          <div className='row justify-content-center'>
            <div className='col-4 text-start'>
              <strong>#</strong>
            </div>
            <div className='col-4 text-center'>
              <strong>PRINCIPAL</strong>
            </div>
            <div className='col-4 text-right'>
              <strong>INTEREST</strong>
            </div>
          </div>

          <div className='row justify-content-center my-2'>
            <div className='col-4 text-start'>
              <div className='text-success fw-bold'>Paid</div>
            </div>
            <div className='col-4 text-center'>
              <div className='text-success fw-bold'>{principalPaid}</div>
            </div>
            <div className='col-4 text-right'>
              <div className='text-success fw-bold'>{interestPaid}</div>
            </div>
          </div>

          <div className='row justify-content-center my-2'>
            <div className='col-4 text-start'>
              <div className='text-warning fw-bold'>Pending</div>
            </div>
            <div className='col-4 text-center'>
              <div className='text-warning fw-bold'>{principalPending}</div>
            </div>
            <div className='col-4 text-right'>
              <div className='text-warning fw-bold'>{interestPending}</div>
            </div>
          </div>
        </div>
      </div>

      <hr className='my-1' />
      
      <div className='card-body py-2 px-3'>
        <div className='px-0'>
          <div className='row justify-content-between align-items-center'>
            <div className='col-lg-6'>
              <div className='text-theme fw-bold'>Interest:<span className='text-success fw-bold'>{interestPerMonth}*</span><br /> <span className='text-muted fw_300 fs_12'>(per month)</span></div>
            </div>
            <div className='col-lg-6'>
              <div className='text-theme text-right fw-bold'><a href='#0'>View Items</a></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

LoanCard.propTypes = {
  rank: PropTypes.number.isRequired,
  date: PropTypes.string.isRequired,
  amount: PropTypes.string.isRequired,
  tenure: PropTypes.string.isRequired,
  principalPaid: PropTypes.string.isRequired,
  interestPaid: PropTypes.string.isRequired,
  principalPending: PropTypes.string.isRequired,
  interestPending: PropTypes.string.isRequired,
  interestPerMonth: PropTypes.string.isRequired,
};

export default LoanCard;
