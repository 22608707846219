import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import LoanCard from '../../components/LoanCard';
import TransactionTable from '../../components/TransactionTable';
import Layout from '../../Layout';
import staticProfileImage from '../../images/tcul-images/user-img.png';
import documentImg from '../../images/svg/docs.svg';
import CustomDropdown from '../../components/CustomDropdown';
import { BsThreeDotsVertical } from "react-icons/bs";

const Profile = () => {
  const [profileImage, setProfileImage] = useState(null);

  const handleImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      reader.onload = (e) => setProfileImage(e.target.result);
      reader.readAsDataURL(event.target.files[0]);
    }
  };

  return (
    <Layout>
      <div className='content-body'>
        <div className='container-fluid pt-5'>
          <div className='row'>
            <div className='col-lg-7 col-sm-12'>
              <div className='card p-4'>
                <div className='d-flex justify-content-between align-items-center profile-info-container'>
                  <div className='d-flex justify-content-start align-items-center gap-3 profile-details'>
                    <div className='profile-container'>
                      <img
                        src={staticProfileImage}
                        alt='profile'
                        className='ds-img-profile'
                      />
                    </div>
                  </div>

                  <CustomDropdown
                    icon={<BsThreeDotsVertical />} 
                    options={[
                      {
                        label: 'Edit Profile', 
                        // onClick: () => ,
                      },
                      {
                        label: 'Delete Profile', 
                        // onClick: () => ,
                      },
                      {
                        label: 'Documents & Info', 
                        // onClick: () => ,
                      }
                    ]} 
                  />
                </div>

                <div className='card-body px-0'>
                  <div className='table-responsive'>
                    <div className='row justify-content-center'>
                      <div className='col-4 text-start'>
                        <span>#As Today</span>
                      </div>
                      <div className='col-4 text-center'>
                        <span>Principal</span>
                      </div>
                      <div className='col-4 text-center'>
                        <span>Interest</span>
                      </div>
                    </div>
                    <div className='row justify-content-center my-2'>
                      <div className='col-4 text-start'>
                        <div className='text-theme fw-bold'>Expected</div>
                      </div>
                      <div className='col-4 text-center'>
                        <div className='text-theme fw-bold'>₹10,00,000</div>
                      </div>
                      <div className='col-4 text-center'>
                        <div className='text-theme fw-bold'>₹10,00,000</div>
                      </div>
                    </div>
                    <div className='row justify-content-center my-2'>
                      <div className='col-4 text-start'>
                        <div className='text-success fw-bold'>Paid</div>
                      </div>
                      <div className='col-4 text-center'>
                        <div className='text-success fw-bold'>₹10,00,000</div>
                      </div>
                      <div className='col-4 text-center'>
                        <div className='text-success fw-bold'>₹10,00,000</div>
                      </div>
                    </div>
                    <div className='row justify-content-center my-2'>
                      <div className='col-4 text-start'>
                        <div className='text-danger fw-bold'>Pending</div>
                      </div>
                      <div className='col-4 text-center'>
                        <div className='text-danger fw-bold'>₹10,00,000</div>
                      </div>
                      <div className='col-4 text-center'>
                        <div className='text-danger fw-bold'>₹10,00,000</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Documents Section */}
            <div className='col-lg-5 col-sm-12'>
              <div className='card'>
                <div className='card-header p-3 border-0'>
                  <h5 className='mb-0 text-black fw-bold fs-20'>Documents</h5>
                </div>
                <hr className='my-0' />
                <div className='card-body pt-2 p-4'>
                  <div className='row'>
                    {['Aadhar Card', 'PAN Card', 'Address Proof', 'Address Proof'].map(
                      (docName, idx) => (
                        <div
                          className='col-lg-6 col-md-6 col-sm-6 col-6 px-3 mb-2'
                          key={idx}
                        >
                          <div className='card mb-0'>
                            <div className='card-header p-2 pb-0 border-0'>
                              <h6 className='mt-2 fs-14'>{docName}</h6>
                              <CustomDropdown
                                icon={<BsThreeDotsVertical />}
                                options={[
                                  {
                                    label: 'Edit',
                                    navigateTo: '/edit',
                                  },
                                  {
                                    label: 'Download',
                                    onClick: () => alert('Downloading...'),
                                  },
                                  {
                                    label: 'Delete',
                                    onClick: () => alert('Deleting...'),
                                  },
                                ]}
                              />
                            </div>

                            <div className='card-body p-2' style={{ cursor: 'pointer' }}>
                              <label htmlFor={`doc-upload-${idx}`}>
                                <img
                                  src={profileImage || documentImg}
                                  alt='document'
                                  className='rounded ds-img-profile'
                                  style={{ width: '100%' }}
                                />
                              </label>
                              <input
                                id={`doc-upload-${idx}`}
                                type='file'
                                accept='image/*'
                                onChange={handleImageChange}
                                style={{ display: 'none' }}
                              />
                            </div>
                          </div>
                        </div>
                      )
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='d-flex justify-content-between align-items-center flex-wrap mb-3'>
            <div className='welcome-text'>
              <h3 className='fw-bolder ps-2'>Loans</h3>
            </div>
            <div className='text-right mb-0'>
              <Link to={'/add-loan'} className='btn btn-primary btn-rounded'>+ Add Loan</Link>
            </div>
          </div>
          
          <div className='row'>
            <LoanCard 
              rank={1}
              date='09 Jul 2024'
              amount='₹ 20,000'
              percentage='2.5%(S.I.)'
              tenure='12 Months'
              principalPaid='₹ 5,000'
              interestPaid='₹ 1,000'
              principalPending='₹ 15,000'
              interestPending='₹ 2,000'
              interestPerYear='2.5%'
              totalPerYear='₹ 22,000'
            />
            <LoanCard 
              rank={1}
              date='09 Jul 2024'
              amount='₹ 20,000'
              percentage='2.5%(S.I.)'
              tenure='12 Months'
              principalPaid='₹ 5,000'
              interestPaid='₹ 1,000'
              principalPending='₹ 15,000'
              interestPending='₹ 2,000'
              interestPerYear='2.5%'
              totalPerYear='₹ 22,000'
            />
          </div>

          <TransactionTable />
        </div>
      </div>
    </Layout>
  );
};

export default Profile;
