import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import profilePic from '../images/tcul-images/user-profile.png'
import CustomDropdown from './CustomDropdown';
import { MdKeyboardArrowRight } from 'react-icons/md';

const Header = () => {
    const navigate = useNavigate();

    return (
        <>
            <div className="header">
                <div className="header-content">
                    <nav className="navbar navbar-expand">
                        <div className="collapse navbar-collapse justify-content-between">
                            <div className="header-left">
                                <div className="dashboard_bar">Dashboard</div>
                            </div>
                            <ul className="navbar-nav header-right">
                                <li className="nav-item">
                                    <div className="input-group search-area d-xl-inline-flex d-none">
                                        <div className="input-group-append">
                                            <button className="input-group-text">
                                                <i className="flaticon-381-search-2"></i>
                                            </button>
                                        </div>
                                        <input type="text" className="form-control" placeholder="Search here..." />
                                    </div>
                                </li>
                                <li className="nav-item dropdown header-profile">
                                    <a className="nav-link" href="#" role="button" data-bs-toggle="dropdown">
                                        <img src={profilePic} width="20" alt="User" />
                                        <div className="header-info">
                                            <span>Parihar Jewellers</span>
                                            <small>Vinesh Parihar</small>
                                        </div>
                                    </a>
                                    <CustomDropdown 
                                        icon={<MdKeyboardArrowRight />}
                                        options={[
                                            {
                                                label: 'Profile',
                                                navigateTo: '/customer-profile/34'
                                            },
                                            {
                                                label: 'Inbox',
                                                navigateTo: '/add-loan'
                                            },
                                            {
                                                label: 'Logout',
                                                // onClick: () => ,
                                            }
                                        ]}
                                    />
                                </li>
                            </ul>
                        </div>
                    </nav>
                </div>
            </div>
        </>
    );
};

export default Header;
