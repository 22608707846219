import React from 'react';
import { createBrowserRouter } from 'react-router-dom';
import Home from './pages/home';
import LoginForm from './pages/auth/LoginForm';
import RegisterForm from './pages/auth/RegisterForm';
import OtpForm from './pages/auth/OtpForm';
import AddCustomer from './pages/customer/AddCustomer';
import EditCustomer from './pages/customer/EditCustomer';
import Listing from './pages/customer/Listing';
import Profile from './pages/customer/Profile';

export const projectRoutes = createBrowserRouter([
  {
    path: '/',
    element: <Home />,
  },
  {
    path: '/user-login',
    element: <LoginForm />,
  },
  {
    path: '/user-register',
    element: <RegisterForm />,
  },
  {
    path: '/verify-otp',
    element: <OtpForm />,
  },
  {
    path: '/add-customer',
    element: <AddCustomer />,
  },
  {
    path: '/update-customer/:customerId',
    element: <EditCustomer />,
  },
  {
    path: '/customer-listing',
    element: <Listing />,
  },
  {
    path: '/customer-profile/:customerId',
    element: <Profile />,
  },
]);
