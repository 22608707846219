// Convert Image to Blob function
export const convertImageToBlob = async (img) => {
    return new Promise((resolve, reject) => {
        if (!(img instanceof Blob)) {
        reject(new Error("Invalid file type. Expected Blob or File."));
        }

        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
        reader.readAsDataURL(img);
    });
};
