import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { API, API_URL, Authtoken, LocalStorage, SessionStorage } from '../../constant';
import { toast } from 'react-toastify';
import loginImageLogo from '../../images/tcul-images/login-logo.png';

const OtpForm = () => {
  const mobileNumber = SessionStorage.getItem('mobile_number_for_otp');
  const navigate = useNavigate();
  const [otp, setOtp] = useState(['', '', '', '']);

  useEffect(() => {
    const isUserLoggedIn = LocalStorage.getItem('authToken');
    if (isUserLoggedIn) {
      navigate('/');
    }
  }, [])

  const handleOtpChange = (event, index) => {
    const value = event.target.value;
    if (!/^[0-9]?$/.test(value)) return;
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    if (value && index < 3) {
      document.getElementById(`otp${index + 2}`).focus();
    }
    if (newOtp.every((digit) => digit !== '')) {
      handleFormSubmit(newOtp.join(''));
    }
  };

  const handleFormSubmit = async (finalOtp) => {
    if (mobileNumber) {
      try {
        const response = await API.post(`${API_URL}/verify-otp`, {
          mobile: mobileNumber,
          otp: finalOtp,
          authtoken: Authtoken,
          device_id: '1234',
        });
        console.log('send otp api call responce', response.data);
        toast.success('Login successful!');
        SessionStorage.removeItem('mobile_number_for_otp');
        setOtp(['', '', '', '']);
        navigate('/');
      } catch (error) {
        console.log('send otp api call error', error);
      }
    }
  };

  const handleResendOtp = async () => {
    if (mobileNumber) {
      try {
        const response = await API.post(`${API_URL}/resend-otp`, {
          mobile: mobileNumber,
          authtoken: Authtoken,
          device_id: '1234',
        });
        console.log('send otp api call responce', response.data);
        toast.success('OTP sent, Please check!');
      } catch (error) {
        console.log('send otp api call error', error);
      }
    }
  };

  return (
    <div className='authincation' style={{ height: '100vh', display: 'flex', alignItems: 'center' }}>
      <div className='container h-100'>
        <div className='row justify-content-center h-100 align-items-center'>
          <div className='col-lg-6 col-md-8 col-sm-12'>
            <div className='authincation-content'>
              <div className='row no-gutters'>
                <div className='col-12'>
                  <div className='auth-form'>
                    <div className='text-center mb-3'><img src={loginImageLogo} alt='' /></div>
                    <h1 className='text-center fw-bold mb-0'>OTP Verification</h1>
                    <p className='text-center mb-4'>Enter the OTP sent to {mobileNumber}</p>

                    <form onSubmit={(e) => e.preventDefault()}>
                      <div className='row justify-content-center'>
                        {otp.map((_, index) => (
                          <div className='mb-3 col-md-3 w-20' key={index}>
                            <input
                              type='text'
                              id={`otp${index + 1}`}
                              className='form-control otp-input'
                              maxLength='1'
                              value={otp[index]}
                              onChange={(e) => handleOtpChange(e, index)}
                              onKeyDown={(e) => {
                                if (e.key === 'Backspace' && otp[index] === '' && index > 0) {
                                  document.getElementById(`otp${index}`).focus();
                                }
                              }}
                            />
                          </div>
                        ))}
                      </div>

                      <div className='text-center mt-4'>
                        <button type='submit' className='btn btn-primary btn-block' onClick={() => handleFormSubmit(otp.join(''))}>Submit</button>
                      </div>
                    </form>

                    <div className='new-account text-center mt-3' style={{ display: 'flex', justifyContent: 'center', gap: '5px' }}>
                      <p>Didn't receive OTP?</p> <p className='text-primary' onClick={handleResendOtp} style={{ cursor: 'pointer' }}>Resend</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OtpForm;
