import React from 'react';

const TransactionTable = () => (
  <div className='pt-3'>
    <div className='row'>
      <div className='col-lg-12'>
        <div className='card'>
          <div className='card-header px-3'>
            <h4 className='card-title'>Transaction</h4>
          </div>

          <div className='card-body px-3 pt-2'>
            <div className='table-responsive'>
              <table className='table header-border table-responsive-sm'>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Date</th>
                    <th>Type (Credit/Debit)</th>
                    <th>Payment Type</th>
                    <th>Mode</th>
                    <th>Amount</th>
                    <th className='text-center'>Receipt</th>
                  </tr>
                </thead>

                <tbody>
                  <tr>
                    <td><a href='javascript:void(0)'>#1</a></td>
                    <td>01 August 2024</td>
                    <td><span className='text-muted'>Oct 16, 2017</span></td>
                    <td><span className='badge light badge-success'>Principal</span></td>
                    <td>UPI</td>
                    <td>₹ 10,000</td>
                    <td>
                      <div className='d-flex justify-content-center'>
                        <a href='#' className='btn btn-primary shadow btn-xs sharp'><i className='fa fa-download'></i></a>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td><a href='javascript:void(0)'>#2</a></td>
                    <td>01 August 2024</td>
                    <td><span className='text-muted'>Oct 12, 2017</span></td>
                    <td><span className='badge light badge-warning light'>Interest</span></td>
                    <td>Cash</td>
                    <td>₹ 10,000</td>
                    <td>
                      <div className='d-flex justify-content-center'>
                        <a href='#' className='btn btn-primary shadow btn-xs sharp'><i className='fa fa-download'></i></a>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td><a href='javascript:void(0)'>#3</a></td>
                    <td>01 August 2024</td>
                    <td><span className='text-muted'>May 18, 2017</span></td>
                    <td><span className='badge light badge-success'>Principal</span></td>
                    <td>UPI</td>
                    <td>₹ 10,000</td>
                    <td>
                      <div className='d-flex justify-content-center'>
                        <a href='#' className='btn btn-primary shadow btn-xs sharp'><i className='fa fa-download'></i></a>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td><a href='javascript:void(0)'>#4</a></td>
                    <td>01 August 2024</td>
                    <td><span className='text-muted'>Apr 28, 2017</span></td>
                    <td><span className='badge light badge-warning'>Interest</span></td>
                    <td>Cash</td>
                    <td>₹ 10,000</td>
                    <td>
                      <div className='d-flex justify-content-center'>
                        <a href='#' className='btn btn-primary shadow btn-xs sharp'><i className='fa fa-download'></i></a>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default TransactionTable;
