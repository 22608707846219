import React from 'react';

const SelectWithLabel = ({ label, isRequired = false, value = '', onChange, options = [], placeholder = '' }) => {
  return (
    <div>
      <label className='form-label'>
        <strong>{label}</strong>{isRequired ? <span className='text-warning'>*</span> : null}
      </label>
      <select
        id='inputState'
        className='default-select form-control wide'
        value={value}
        onChange={onChange}
        required={isRequired}
      >
        <option value='' disabled>{placeholder}</option>
        {options?.map((option, index) => (
          <option key={index} value={option}>
            {option}
          </option>
        ))}
      </select>
    </div>
  );
};

export default SelectWithLabel;
