import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { API, API_URL, Authtoken } from '../../constant';
import staticProfileImage from '../../images/tcul-images/user-img.png';
import InputWithLabel from '../../components/InputWithLabel';
import TextareaWithLabel from '../../components/TextareaWithLabel';
import UploadButtonWithLabel from '../../components/UploadButtonWithLabel';
import SelectWithLabel from '../../components/SelectWithLabel';
import { convertImageToBlob } from '../../utils/convertImageToBlob';
import Layout from '../../Layout';

const EditCustomer = () => {
  const { customerId } = useParams();
    const navigate = useNavigate();
    const [userInfo, setUserInfo] = useState({
        first_name: '',
        middle_name: '',
        last_name: '',
        mobile_number: '',
        alt_mobile_number: '',
        email: '',
        address: '',
        note: '',
        aadharCardFile: null,
        aadharCardFileName: '',
        panCardFile: null,
        panCardFileName: '',
        document_type: '',
        otherDocFile: null,
        otherDocFileName: ''
    });
    const [profileImage, setProfileImage] = useState(null);
    const [altMobileVisible, setAltMobileVisible] = useState(false);
    const [otherDocsVisible, setOtherDocsVisible] = useState(false);
    const documentTypeOptions = ['Address Proof', 'Id Proof', 'Other']; // 'address_proof', 'id_proof', 'other'

    const toggleAltMobile = () => setAltMobileVisible(!altMobileVisible);

    const toggleOtherDocs = () => setOtherDocsVisible(!otherDocsVisible);

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = () => setProfileImage(reader.result);
            reader.readAsDataURL(file);
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (userInfo && userInfo.first_name && userInfo.last_name && userInfo.email && userInfo.mobile_number && userInfo.address && userInfo.note) {
          try {
            const photoBlob = profileImage instanceof File ? await convertImageToBlob(profileImage) : profileImage;
            const aadhaarCardBlob = userInfo.aadharCardFile instanceof File ? await convertImageToBlob(userInfo.aadharCardFile) : null;
            const panCardBlob = userInfo.panCardFile instanceof File ? await convertImageToBlob(userInfo.panCardFile) : null;
            const otherDocBlob = userInfo.otherDocFile instanceof File ? await convertImageToBlob(userInfo.otherDocFile) : null;

            const documentType = {
                type: userInfo.document_type === "Address Proof"
                    ? "address_proof"
                    : userInfo.document_type === "Id Proof"
                    ? "id_proof"
                    : userInfo.document_type === "Other"
                    ? "other"
                    : '',
                document: otherDocBlob,
            };

            const response = await API.post(`${API_URL}/customers/update`, {
              customer_id: customerId,
              photo: photoBlob,
              first_name: userInfo.first_name,
              middle_name: userInfo.middle_name,
              last_name: userInfo.last_name,
              email: userInfo.email,
              mobile: userInfo.mobile_number,
              alternate_mobile: userInfo.alt_mobile_number,
              address: userInfo.address,
              note: userInfo.note,
              aadhaar_card: aadhaarCardBlob,
              pan_card: panCardBlob,
              document_types: userInfo.document_type && otherDocBlob ? [documentType] : [],
              authtoken: Authtoken,
            });
      
            console.log("update customer api call response", response.data);
            toast.success("Customer updated successfully!");
            setUserInfo(null);
            navigate("/");
          } catch (error) {
            console.log("update customer api call error", error);
          }
        }
    };

    return (
        <Layout>
            <div className="content-body">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-12 col-lg-12">
                            <div className="card">
                                <div className="card-header"><h4 className="card-title">Edit Customer</h4></div>

                                <div className="card-body">
                                    <div className="basic-form">
                                        <form onSubmit={handleSubmit}>
                                            <div className="row">
                                                <div className='d-flex justify-content-center'>
                                                    <div className='profile-container' style={{ position: 'relative', textAlign: 'center', marginBottom: '20px' }}>
                                                        <label htmlFor='file-upload' style={{ cursor: 'pointer' }}>
                                                            <img
                                                                src={profileImage || staticProfileImage}
                                                                alt=''
                                                                style={{
                                                                    width: '120px',
                                                                    height: '120px',
                                                                    borderRadius: '60%',
                                                                    objectFit: 'cover',
                                                                    display: 'block',
                                                                    margin: '0 auto',
                                                                }}
                                                            />
                                                            <div className='upload-icon' style={{ position: 'absolute', bottom: '0', right: '0' }}>
                                                                <i className='fas fa-camera'></i>
                                                            </div>
                                                        </label>
                                                        <input
                                                            id='file-upload'
                                                            type='file'
                                                            accept='image/*'
                                                            onChange={handleImageChange}
                                                            style={{ display: 'none' }}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="mb-3 col-md-6">
                                                    <InputWithLabel
                                                        label='First name'
                                                        inputType='text'
                                                        placeholder='Enter first name'
                                                        isRequired={true}
                                                        value={userInfo.first_name}
                                                        onChange={(e) => setUserInfo({ ...userInfo, first_name: e.target.value })}
                                                    />
                                                </div>

                                                <div className="mb-3 col-md-6">
                                                    <InputWithLabel
                                                        label='Middle name'
                                                        inputType='text'
                                                        placeholder='Enter middle name'
                                                        isRequired={true}
                                                        value={userInfo.middle_name}
                                                        onChange={(e) => setUserInfo({ ...userInfo, middle_name: e.target.value })}
                                                    />
                                                </div>

                                                <div className="mb-3 col-md-6">
                                                    <InputWithLabel
                                                        label='Last name'
                                                        inputType='text'
                                                        placeholder='Enter last name'
                                                        isRequired={true}
                                                        value={userInfo.last_name}
                                                        onChange={(e) => setUserInfo({ ...userInfo, last_name: e.target.value })}
                                                    />
                                                </div>

                                                <div className="mb-3 col-md-6">
                                                    <InputWithLabel
                                                        label='Email'
                                                        inputType='email'
                                                        placeholder='Enter email'
                                                        isRequired={true}
                                                        value={userInfo.email}
                                                        onChange={(e) => setUserInfo({ ...userInfo, email: e.target.value })}
                                                    />
                                                </div>

                                                <div className="mb-3 col-md-6">
                                                    <InputWithLabel
                                                        label='Mobile number'
                                                        inputType='tel'
                                                        placeholder='Enter mobile number'
                                                        isRequired={true}
                                                        pattern='[0-9]+'
                                                        value={userInfo.mobile_number}
                                                        onChange={(e) => setUserInfo({ ...userInfo, mobile_number: e.target.value })}
                                                    />

                                                    <button className="btn btn-primary-tcul px-0" type="button" onClick={toggleAltMobile}>
                                                        <i className="fas fa-plus"></i> Alternate Mobile Number
                                                    </button>

                                                    {altMobileVisible && (
                                                        <div className="mb-3 position-relative">
                                                            <InputWithLabel
                                                                label='Alternate mobile number'
                                                                inputType='tel'
                                                                placeholder='Enter alternate mobile number'
                                                                isRequired={false}
                                                                pattern='[0-9]+'
                                                                value={userInfo.alt_mobile_number || ''}
                                                                onChange={(e) => setUserInfo({ ...userInfo, alt_mobile_number: e.target.value })}
                                                            />
                                                        </div>
                                                    )}
                                                </div>

                                                <div className="mb-3 col-12">
                                                    <TextareaWithLabel
                                                        rows='4'
                                                        label='Address'
                                                        inputType='text'
                                                        placeholder='Enter address'
                                                        isRequired={true}
                                                        value={userInfo.address}
                                                        onChange={(e) => setUserInfo({ ...userInfo, address: e.target.value })}
                                                    />
                                                </div>

                                                <div className="mb-0 col-md-6">
                                                    <UploadButtonWithLabel
                                                        label={'Upload Aadhar Card'}
                                                        placeholder={userInfo && userInfo.aadharCardFileName ? userInfo.aadharCardFileName : 'Upload Document'}
                                                        isRequired={true}
                                                        onFileChange={(file, fileName) => setUserInfo({ ...userInfo, aadharCardFileName: fileName, aadharCardFile: file })}
                                                        fieldId="aadhar-card-upload"
                                                    />
                                                </div>

                                                <div className="mb-0 col-md-6">
                                                    <UploadButtonWithLabel
                                                        label={'Upload PAN Card'}
                                                        placeholder={userInfo && userInfo.panCardFileName ? userInfo.panCardFileName : 'Upload Document'}
                                                        isRequired={true}
                                                        onFileChange={(file, fileName) => setUserInfo({ ...userInfo, panCardFileName: fileName, panCardFile: file })}
                                                        fieldId="pan-card-upload"
                                                    />
                                                </div>

                                                <div className="mb-2 col-md-6">
                                                    <button className="btn btn-primary-tcul px-0" type="button" onClick={toggleOtherDocs}>
                                                        <i className="fas fa-plus"></i> Add Other Document
                                                    </button>
                                                </div>

                                                {otherDocsVisible && (
                                                    <div className="row mb-3">
                                                        <div className="col-md-6">
                                                            <SelectWithLabel
                                                                label='Document type'
                                                                placeholder='Select document type'
                                                                isRequired={true}
                                                                options={documentTypeOptions}
                                                                value={userInfo.document_type || ''}
                                                                onChange={(e) => setUserInfo({ ...userInfo, document_type: e.target.value })}
                                                            />
                                                        </div>

                                                        <div className="col-md-6">
                                                            <UploadButtonWithLabel
                                                                label={'Upload Other Document'}
                                                                placeholder={userInfo && userInfo.otherDocFileName ? userInfo.otherDocFileName : 'Upload Document'}
                                                                isRequired={false}
                                                                onFileChange={(file, fileName) => setUserInfo({ ...userInfo, otherDocFileName: fileName, otherDocFile: file })}
                                                                fieldId="other-document-upload"
                                                            />
                                                        </div>
                                                    </div>
                                                )}

                                                <div className="mb-3 col-12">
                                                    <TextareaWithLabel
                                                        rows='4'
                                                        label='Note'
                                                        inputType='text'
                                                        placeholder='Enter note'
                                                        isRequired={true}
                                                        value={userInfo.note}
                                                        onChange={(e) => setUserInfo({ ...userInfo, note: e.target.value })}
                                                    />
                                                </div>
                                            </div>

                                            {/* <button type="button" className="btn btn-outline-primary">Save & Create Loan</button> */}
                                            <button type="submit" className="btn btn-primary" style={{width: '100%'}}>Save</button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default EditCustomer;
