import React from 'react';

const Footer = () => {
    return (
        <div className="footer">
            <div className="copyright">
                <p className="text-white">
                    © Designed &amp; Developed by{' '}
                    <a className="text_orange fw_600" href="https://technicul.com/" target="_blank" rel="noopener noreferrer">
                        Technicul
                    </a>{' '}
                    2024
                </p>
                <p className="underline text-white">
                    Product of{' '}
                    <a className="text_orange fw_600" href="https://technicul.com/" target="_blank" rel="noopener noreferrer">
                        Technicul
                    </a>
                </p>
            </div>
        </div>
    );
};

export default Footer;
