import React, { useState, useEffect, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { API, API_URL } from '../../constant';
import Layout from '../../Layout';
import staticProfileImage from '../../images/tcul-images/user-img.png';
import useDeviceType from '../../utils/useDeviceType';
import CustomDropdown from '../../components/CustomDropdown';
import { BsThreeDotsVertical } from "react-icons/bs";

const customers = [
  {
    id: 'ndo2232',
    name: 'ROhan Kurane',
    contact: 79274927327,
    whatsapp: 79274927327,
    email: 'rohan.technicul@gmail.com',
    paid: 500000,
    pending: 300000,
    principal: 800000,
  },
  {
    id: 'ndo2232',
    name: 'Aryan Wathre',
    contact: 79274927327,
    whatsapp: 79274927327,
    email: 'aryan.technicul@gmail.com',
    paid: 700000,
    pending: 500000,
    principal: 1200000,
  },
  {
    id: 'ndo2232',
    name: 'ROhan Kurane',
    contact: 79274927327,
    whatsapp: 79274927327,
    email: 'rohan.technicul@gmail.com',
    paid: 500000,
    pending: 300000,
    principal: 800000,
  },{
    id: 'ndo2232',
    name: 'Shubh Shah',
    contact: 79274927327,
    whatsapp: 79274927327,
    email: 'shubh.technicul@gmail.com',
    paid: 200000,
    pending: 500000,
    principal: 700000,
  }
];

const Listing = () => {
  const deviceType = useDeviceType(); 
  const navigate = useNavigate();
  // const [customers, setCustomers] = useState([]);

  // const fetchAllCustomers = useCallback(async () => {
  //   try {
  //     const response = await API.post(`${API_URL}/customers`);
  //     setCustomers(response ?? []);
  //   } catch (error) {
  //     console.error('Error fetching customers:', error);
  //   }
  // }, []);

  // useEffect(() => {
  //   fetchAllCustomers();
  // }, [fetchAllCustomers]);

  const CustomerRow = ({ customer, index }) => (
    <tr key={customer?.id}>
      <td><strong>{index + 1}</strong></td>
      <td>
        <div className='d-flex align-items-center'>
          <img
            src={customer?.photo ? customer?.photo : staticProfileImage}
            className='rounded-lg-tcul me-2'
            width='24'
            alt='User'
          />
          <h5 className='w-space-no fw-normal'>
            {customer?.name}
            <br />
            <small className='text-muted'>{customer?.contact}</small>
          </h5>
        </div>
      </td>
      <td><span className='badge light badge-success'>{customer?.paid}</span></td>
      <td><span className='badge light badge-danger'>{customer?.pending}</span></td>
      <td><span className='badge light badge-warning'>{customer?.principal}</span></td>
      <td>
        <div className='icon-container'>
          <a href={`https://wa.me/${customer?.whatsapp}`} target='_blank' rel='noopener noreferrer' className='icon'>
            <i className='fab fa-whatsapp'></i>
          </a>
          <a href={`mailto:${customer?.email}`} className='icon'>
            <i className='fas fa-envelope'></i>
          </a>
        </div>
      </td>
      <td>
        <CustomDropdown 
          icon={<BsThreeDotsVertical />}
          options={[
            {
              label: 'View Profile',
              navigateTo: '/customer-profile/34'
            },
            {
              label: 'Add Loan',
              navigateTo: '/add-loan'
            },
          ]} 
        />
      </td>
    </tr>
  );

  return (
    <Layout>
      <div className={deviceType === 'mobile' ? '' : 'content-body'}>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='card'>
                <div className='card-header p-3' style={{ backgroundColor: '#7033FF' }}>
                  <h2 className='card-title col-sm-6 p-md-0 fw-bold' style={{ color: '#ffffff' }}>Customers</h2>
                  <Link to='/add-customer'>
                    <button type='button' className='btn btn-warning py-2 fw-bold'>Add Customer</button>
                  </Link>
                </div>

                <div className='card-body p-3'>
                  <div className='table-responsive'>
                    <table className='table table-responsive-md'>
                      <thead>
                        <tr>
                          <th style={{ width: '80px' }}><strong>#</strong></th>
                          <th><strong>CUSTOMER</strong></th>
                          <th><strong>PAID</strong></th>
                          <th><strong>PENDING</strong></th>
                          <th><strong>PRINCIPAL</strong></th>
                          <th><strong>CONTACT</strong></th>
                          <th></th>
                        </tr>
                      </thead>

                      <tbody>
                        {customers?.map((customer, index) => (
                          <CustomerRow customer={customer} index={index} key={customer?.id} />
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Listing;
