import React from 'react';
import { Link } from 'react-router-dom';
import girviBrandImg from '../images/tcul-images/login-logo.png'
import CustomDropdown from './CustomDropdown';
import { MdKeyboardArrowRight } from 'react-icons/md';

const Sidebar = () => {
    return (
        <div className='deznav' style={{height: '90vh'}}>
            <Link to='/' className='brand-logo' style={{display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '30px', paddingBottom: '30px'}}>
                <img className='logo-abbr' src={girviBrandImg} alt='' />
            </Link>

            <div className='deznav-scroll' style={{paddingLeft: '20px', backgroundColor: '#fff'}}>
                <ul className='metismenu' id='menu'>
                    <li>
                        <Link className='ai-icon' to='/' aria-expanded='false'>
                            <i className='flaticon-381-networking'></i>
                            <span className='nav-text'>Home</span>
                        </Link>
                    </li>
                    <li>
                        <Link className='ai-icon' to='/customer-listing' aria-expanded='false'>
                            <i className='flaticon-381-user-8'></i>
                            <span className='nav-text'>Customers</span>
                        </Link>
                    </li>
                    <li>
                        <Link className='ai-icon' href='' aria-expanded='false'>
                            <i className='flaticon-381-network'></i>
                            <span className='nav-text'>Dues</span>
                        </Link>
                    </li>
                    <li>
                        <Link className='ai-icon' href='' aria-expanded='false'>
                            <i className='flaticon-381-layer-1'></i>
                            <span className='nav-text'>Loans</span>
                        </Link>
                    </li>
                    <li>
                        <Link className='has-arrow ai-icon' href='' aria-expanded='false'>
                            <i className='flaticon-381-user-4'></i>
                            <span className='nav-text'>Account</span>

                            <CustomDropdown 
                                icon={<MdKeyboardArrowRight />}
                                options={[
                                    {
                                        label: 'Settings',
                                        // navigateTo: 
                                    },
                                    {
                                        label: 'Download Report',
                                        // navigateTo:
                                    },
                                    {
                                        label: 'Buy Subscription',
                                        // navigateTo: 
                                    },
                                    {
                                        label: 'My Subscription',
                                        // navigateTo:
                                    },
                                    {
                                        label: 'Help & Support',
                                        // navigateTo: 
                                    },
                                    {
                                        label: 'Terms & Conditions',
                                        // navigateTo:
                                    },
                                    {
                                        label: 'Privacy Policy',
                                        // navigateTo: 
                                    },
                                    {
                                        label: 'Refund Policy',
                                        // navigateTo:
                                    },
                                    {
                                        label: 'Logout',
                                        // onClick: () => ,
                                    }
                                ]} 
                            />
                        </Link>
                    </li>
                </ul>

                <div className='copyright' style={{position: 'absolute', bottom: 0}}>
                    <p className='text-center text_orange'>
                        <strong className='text_primary'>Girvi Dashboard</strong> © 2024 All Rights Reserved
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Sidebar;
