import React from 'react'
import Layout from '../Layout'

const home = () => {
  return (
    <Layout>
      <div>
        
      </div>
    </Layout>
  )
}

export default home
