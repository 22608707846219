import React, { useState } from 'react';

const UploadButtonWithLabel = ({ label, placeholder, onFileChange, accept = 'image/*', isRequired = false, fieldId }) => {
  const [showCrossBtn, setShowCrossBtn] = useState(false);

  const handleFileChange = (event) => {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      if (onFileChange && file) {
        onFileChange(file, file.name);
        setShowCrossBtn(true);
      }
    } else {
      if (onFileChange) {
        onFileChange(null);
        setShowCrossBtn(false);
      }
    }
  };

  const handleRemoveFile = () => {
    if (onFileChange) {
      onFileChange(null);
      setShowCrossBtn(false);
    }
    document.getElementById(fieldId).value = '';
  };

  return (
    <div>
      <label className="form-label">
        <strong>{label}</strong>{isRequired && <span className="text-warning"> *</span>}
      </label>
      
      <div className="upload-field-container form-control justify-content-start">
        <label htmlFor={fieldId} className="upload-label mb-0" style={{ cursor: 'pointer' }}>
          <i className="flaticon-381-upload"></i><span>{placeholder}</span>
        </label>
        <input
          id={fieldId}
          type="file"
          accept={accept}
          style={{ display: 'none' }}
          onChange={handleFileChange}
        />
        {showCrossBtn ? (
          <button
            className="remove-field-btn"
            type="button"
            onClick={handleRemoveFile}
            style={{marginLeft: '20px', border: 'none', backgroundColor: 'white', padding: '5px'}}
          >
            <i className="fas fa-times"></i>
          </button>
        ) : (null)}
      </div>
    </div>
  );
};

export default UploadButtonWithLabel;
