import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const CustomDropdown = ({ icon, options }) => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div style={{ position: 'relative', display: 'inline-block' }}>
      <div onClick={toggleDropdown} style={{ cursor: 'pointer' }}>
        {icon}
      </div>

      {isOpen && (
        <div
          style={{
            position: 'absolute',
            top: '30px',
            right: 0,
            backgroundColor: 'white',
            border: '1px solid #ccc',
            borderRadius: '8px',
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
            zIndex: 4,
            width: '140px',
            textAlign: 'center',
          }}
        >
          {options?.map((option, index) => (
            <div
              key={index}
              style={{
                padding: '10px',
                color: 'black',
                cursor: 'pointer',
                borderBottom: index !== options.length - 1 ? '1px solid #eee' : 'none',
              }}
              onClick={() => {
                if (option.onClick) {
                  option.onClick();
                } else if (option.navigateTo) {
                  navigate(option.navigateTo);
                }
                setIsOpen(false);
              }}
            >
              {option.label}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default CustomDropdown;
