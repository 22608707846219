import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { API, API_URL, Authtoken, LocalStorage, SessionStorage } from '../../constant';
import { toast } from 'react-toastify';
import loginImageLogo from '../../images/tcul-images/login-logo.png';
import InputWithLabel from '../../components/InputWithLabel';

const LoginForm = () => {
  const navigate = useNavigate();
  const [mobileNumber, setMobileNumber] = useState('');

  useEffect(() => {
    const isUserLoggedIn = LocalStorage.getItem('authToken');
    if (isUserLoggedIn) {
      navigate('/');
    }
  }, [])

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    if (mobileNumber) {
      try {
        const response = await API.post(`${API_URL}/login`, {
          mobile: mobileNumber,
          authtoken: Authtoken,
          device_id: '1234',
        });
        console.log('send otp api call responce', response.data);
        toast.success('OTP sent, Please check!');
        SessionStorage.setItem('mobile_number_for_otp', mobileNumber);
        setMobileNumber('');
        navigate('/verify-otp');
      } catch (error) {
        console.log('send otp api call error', error);
      }
    }
  };

  return (
    <div className='authincation' style={{ height: '100vh', display: 'flex', alignItems: 'center' }}>
      <div className='container h-100'>
        <div className='row justify-content-center h-100 align-items-center'>
          <div className='col-lg-5 col-md-10 col-sm-12'>
            <div className='authincation-content'>
              <div className='row no-gutters'>
                <div className='col-12'>
                  <div className='auth-form px-4'>
                    <div className='text-center mb-3'><a href='#'><img src={loginImageLogo} alt='' /></a></div>
                    <h4 className='text-center mb-4'>Login to your account</h4>

                    <form onSubmit={handleFormSubmit}>
                      <div className='form-group'>
                        <InputWithLabel
                          label='Mobile number'
                          inputType='tel'
                          placeholder='Enter mobile number'
                          isRequired={true}
                          pattern='[0-9]+'
                          value={mobileNumber || ''}
                          onChange={(e) => setMobileNumber(e.target.value)}
                        />
                      </div>

                      <div className='mt-4'>
                        <p className='text_orange'>We will send you a OTP (One Time Password)</p>
                        <button type='submit' className='btn btn-primary btn-block'>Get OTP</button>
                      </div>
                    </form>

                    <div className='new-account text-center mt-3'>
                      <p>Don't have an account? <a className='text-primary' href='/user-register'>Register</a></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginForm;
