import React, { useEffect } from 'react';
import { RouterProvider } from 'react-router-dom';
import { projectRoutes } from './routes';
import './styles/style.css';
import './styles/technicul.css';
import AOS from 'aos';
import 'aos/dist/aos.css';

const App = () => {
  // animation on scroll AOS package initialization
  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: 'ease',
      once: true,
      mirror: false,
    });
  }, []);

  return (
    <RouterProvider router={projectRoutes} />
  );
}

export default App;
